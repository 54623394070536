import axios from 'axios';
import React, { Component } from 'react'
import { Col, Container, Row, Button, Table, Badge, Form, Alert } from 'react-bootstrap'
import { FaRegCheckCircle, FaArrowLeft } from "react-icons/fa";
import { Redirect } from 'react-router-dom';
import Nav from './Navbar/Nav';
import CheckboxButton from './CheckboxButton/CheckboxButton';

export default class Ingreso extends Component{
    
    constructor(params){
        super(params)
        this.state = {
            venta: JSON.parse(localStorage.getItem('venta')),
            validadas : [],
            aValidar : [],
            checked : false,
            isFraude: false
        };
    }

    
    handleChange = (id,value) => {
        let aValidar = this.state.aValidar;
        if(id){
            var index = aValidar.indexOf(id)
            if (index !== -1) {
                aValidar.splice(index, 1);
            }else{
                aValidar.push(id);
            }
            this.setState({ aValidar :aValidar });
        }
    }

    getClassButton = (id) =>{
        let validados = JSON.parse(localStorage.getItem('validados'));
        let isValidado = "NO_INGRESADO";
        
        if(navigator.onLine){
            let venta = this.state.venta;
            venta.detalle.forEach((detalle)=>{
                if(detalle.venta_detalle_codigo_impreso === id){
                    if(detalle.chequeo_estatus === 1){
                        isValidado = 'INGRESADO'
                    }else if(detalle.chequeo_estatus === 2){
                        isValidado = 'SALIO'
                    }else{
                        isValidado = 'NO_INGRESADO'
                    }
                    return;
                }
            });
        }else{
            if(validados){
                validados.forEach((validado)=>{
                    if(validado.id === id){
                        isValidado = validado.estado;
                        return;
                    }
                });
            }
        }
        
        let classColor = 'success';

        if(isValidado === 'INGRESADO'){
            classColor = 'danger';
        }else if(isValidado === 'SALIO'){
            classColor = 'warning';
        }
        
        return classColor;
    }

    checkeaEstado = (id) => {
        let validados = JSON.parse(localStorage.getItem('validados'));
        let isValidado = "NO_INGRESADO";
        
        if(navigator.onLine){
            let venta = this.state.venta;
            venta.detalle.forEach((detalle)=>{
                if(detalle.venta_detalle_codigo_impreso === id){
                    if(detalle.chequeo_estatus === 1){
                        isValidado = 'INGRESADO'
                    }else if(detalle.chequeo_estatus === 2){
                        isValidado = 'SALIO'
                    }else{
                        isValidado = 'NO_INGRESADO'
                    }
                    return;
                }
            });
        }else{
            if(validados){
                validados.forEach((validado)=>{
                    if(validado.id === id){
                        isValidado = validado.estado;
                        return;
                    }
                });
            }
        }
        
        return isValidado;
    }

    volver = () =>{
        this.setRedirect();
    }

    registrar = () =>{
        let aValidar = this.state.aValidar;
        let dataValidados = localStorage.getItem('validados');
        let use_type = sessionStorage.getItem('use_type');
        let validados = [];
        let validadosSend = [];
        
        if(dataValidados){
            validados = JSON.parse(dataValidados);
        }
        aValidar.forEach(id => {
            let estado = 'INGRESADO'

            validados.forEach((validado,i) => {
                if(validado.id === id && validado.estado === 'INGRESADO'){
                    estado = 'SALIO'
                    validados.splice(i,1);
                }
            });

            let datos = {
                id: id,
                estado: estado
            };
            validadosSend.push(datos);
            validados.push(datos);
        });

        if(navigator.onLine && use_type === 'online'){
            const config = {
                headers: {
                    Authorization: 'Bearer '+sessionStorage.getItem('token')
                }
            }
            const datos = {chequeo_estatus: validadosSend};
            axios.post('registrar-ingresos',datos,config).then(
                res => {
                    console.log(res);
                    if(res){
                        
                    }
                    localStorage.setItem('validados',JSON.stringify(validados));
                },
                err => {
                    console.log(err);
                }
            )
        }else{
            localStorage.setItem('validados',JSON.stringify(validados));
        }
        
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
      }
      
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/chequeo' />
        }
    }

    renderAlertFraud = () => {
        if (this.state.venta.estatus_pago === 'PAGO_FRAUDE_DEVUELTO') {
            return <Alert variant="danger" className="mt-3">
            <Alert.Heading>Esta Venta fue Devuelta por Fraude.</Alert.Heading>
            <p>Pida original o foto de la Tarjeta con la que se pagaron las entradas y verifique los últimos 4 números.</p>
            <p>Tarjeta: {this.state.venta.numero_tarjeta}</p>
          </Alert>
        }

        if (this.state.venta.estatus_pago === 'PAGO_DEVUELTO') {
            return <Alert variant="danger" className="mt-3">
                <br></br>
            <Alert.Heading>Esta Venta fue Devuelta</Alert.Heading>
            <br></br>
          </Alert>
        }

        if (this.state.venta.estatus_pago === 'PAGO_CON_CONTRACARGO') {
            return <Alert variant="danger" className="mt-3">
            <Alert.Heading>Pago con Contracargo</Alert.Heading>
          </Alert>
        }
    }

    renderButtonRegister = () => {

        let buttonDisable = false;

        if(this.state.venta.estatus_pago === 'PAGO_FRAUDE_DEVUELTO'
            || this.state.venta.estatus_pago === 'PAGO_DEVUELTO'
            || this.state.venta.estatus_pago === 'PAGO_CON_CONTRACARGO') {
                buttonDisable = true;
            }

        return <Row className="mt-3">
                <Col>
                    <Button disabled={buttonDisable} type="submit" className="mb-2 btn-ew btn-success" id="btnSincronizar" block onClick={this.registrar}>
                        <FaRegCheckCircle></FaRegCheckCircle> Registrar y Volver
                    </Button>
                </Col>
            </Row>
    }

    render(){
        

        return (
            <><Nav isLogin={false}></Nav>
            <Container>
                {this.renderAlertFraud()}
            <Form onSubmit = {this.handleSubmit}>
                {this.renderRedirect()}
                {this.renderButtonRegister()}
                {
                this.state.venta.detalle.map((detalle) =>
                <Row key={detalle.venta_detalle_codigo_impreso}>
                    <Col>
                                <Table responsive className="table-ingreso text-center mb-0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="mt-3 mb-0 text-left">
                                                    <CheckboxButton disable={this.state.venta.estatus_pago === 'PAGO_FRAUDE_DEVUELTO' || this.state.venta.estatus_pago === 'PAGO_DEVUELTO' || this.state.venta.estatus_pago === 'PAGO_CON_CONTRACARGO'} parentCallback={this.handleChange} id={detalle.venta_detalle_codigo_impreso} variante={(this.getClassButton(detalle.venta_detalle_codigo_impreso))}></CheckboxButton>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="mt-3 mb-0 text-left">
                                                    {detalle.venta_detalle_lugar}<br></br>
                                                    ${detalle.venta_detalle_precio}
                                                </p>
                                            </td>
                                            <td>
                                                <div className="mt-3 mb-0 text-center">
                                                    <Badge variant={this.getClassButton(detalle.venta_detalle_codigo_impreso)}>{this.checkeaEstado(detalle.venta_detalle_codigo_impreso)}</Badge>
                                                </div>
                                            </td>
                                        </tr>
                                     </tbody>
                                </Table>
                                {detalle.venta_detalle_item} - {detalle.venta_detalle_sector}
                                {detalle.nombre_persona_entrada && <><br></br><span style={{fontSize: "0.8rem"}}>{detalle.nombre_persona_entrada}</span></>}
                                {detalle.email_persona_entrada && <><br></br><span style={{fontSize: "0.8rem"}}>{detalle.email_persona_entrada}</span></>}
                                {detalle.dni_persona_entrada && <><br></br><span style={{fontSize: "0.8rem"}}>DNI: {detalle.dni_persona_entrada}</span></>}
                    </Col>
                </Row>) }
                <Row>
                    <Col>
                        <Table responsive>
                        <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Fecha Compra</th>
                                    <th>DNI Comprador</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.venta.comprador}</td>
                                    <td>{this.state.venta.fecha_venta}</td>
                                    <td>{this.state.venta.venta_dni_pagador}</td>
                                    <td>{this.state.venta.venta_monto_totalneto}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {this.renderButtonRegister()}
                <Row>
                    <Col>
                        <Button type="submit" className="mb-2 btn-ew btn-warning" id="btnSincronizar" block onClick={this.volver}>
                        <FaArrowLeft></FaArrowLeft> Volver
                        </Button>
                    </Col>
                </Row>
                </Form>
            </Container>
            </>
        )
    }
}