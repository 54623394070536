import './App.css';


import { BrowserRouter as Router,Route, Switch } from 'react-router-dom';
import Login from "./components/Login";
import Home from "./components/Home";
import Chequeo from './components/Chequeo';
import Ingreso from './components/Ingreso';
import Total from './components/Total';
import Logout from './components/Logout';
import Busqueda from './components/Busqueda';
import Inicio from './components/Inicio';
import Login2 from './components/Login2';
import Ingreso2 from './components/Ingreso2';
import ValidacionIdentidad from './components/ValidacionIdentidad';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/home" component={Home}></Route>
          <Route path="/chequeo" component={Chequeo}></Route>
          <Route path="/ingreso" component={Ingreso}></Route>
          <Route path="/ingreso2" component={Ingreso2}></Route>
          <Route path="/total" component={Total}></Route>
          <Route path="/logout" component={Logout}></Route>
          <Route path="/busqueda" component={Busqueda}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/login2" component={Login2}></Route>
          <Route path="/validacion-identidad" component={ValidacionIdentidad}></Route>
          <Route path="/" component={Inicio}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
