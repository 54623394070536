import React, { Component } from 'react'
import { Col, Container, Row, Form, Button,FormControl, Modal, Image, Spinner } from 'react-bootstrap'
import QrReader from 'react-qr-reader'
import { Redirect } from 'react-router-dom';
import { FaCheck, FaEquals, FaArrowLeft,FaUserCheck } from "react-icons/fa";
import axios from 'axios';
import Nav from './Navbar/Nav';


export default class Chequeo extends Component{
    state = {
        result: '',
        recintoNombre: '',
        codigo : ''
    }

    constructor(params){
        super(params)
        let sesiones = JSON.parse(localStorage.getItem('sesiones'));
        let sesion_id = localStorage.getItem('sesion_id');
        localStorage.setItem('busca_apellido','');
        let evento = "";

        if(sesiones.recinto.tipo === 'USER'){
            sesiones.sesiones.forEach(sesion => {
                if(sesion.id === sesion_id){
                    evento = sesion.evento;
                }
            });
        }else{
            evento = sesiones.recinto.recin_nombre_interno;
        }
        

        this.state = {
            recintoNombre : sesiones.recinto.recin_nombre_interno,
            evento: evento,
            showModalExplanation: true
        };
    }

    componentDidMount(){
        let sesion_id = localStorage.getItem('sesion_id');

        if(!sesion_id || sessionStorage.getItem('token') === ""){
            this.setState({sinSesion : true});
        }

        if(sesion_id === 'todas'){
            this.setState({
                viewButtonApellido :true,
                viewButtonTotalizado: true
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        if(this.codigo !== undefined || this.apellido !== undefined){
            if(this.codigo !== undefined)
                this.validaCodigo(this.codigo);
            else{
                localStorage.setItem('busca_apellido',this.apellido);
                this.validaApellido(this.apellido);
            }    
        }
    }

    validaApellido(busca_apellido){
        let resultBusqueda = [];
        if(navigator.onLine){
            const config = {
                headers: {
                    Authorization: 'Bearer '+sessionStorage.getItem('token')
                }
            }
            const datos = 'sesion_codigo='+localStorage.getItem('sesion_id')+'&apellido='+busca_apellido;
            axios.post('validate-apellido',datos,config).then(
                res => {
                    if(res.data.length > 0){
                        res.data.forEach((venta) => {
                            resultBusqueda.push(venta[0]);
                        });
                        localStorage.setItem('ventas_buscadas',JSON.stringify(resultBusqueda));
                        this.setState({
                            redirectBusqueda: true
                            })
                    }else{
                        this.setState({noExisteApellido: true});
                    }
                },
                err => {
                    console.log(err);
                }
            )
        }else{
            let sesionVentas = JSON.parse(localStorage.getItem(localStorage.getItem('sesion_id')));
            sesionVentas.forEach((venta) => {
                if(venta.comprador_apellido !== "" && venta.comprador_apellido.toLowerCase()  === busca_apellido.toLowerCase()){
                    resultBusqueda.push(venta);
                }
            });
            if(resultBusqueda.length > 0){
                localStorage.setItem('ventas_buscadas',JSON.stringify(resultBusqueda));
                this.setState({
                    redirectBusqueda: true
                    })
            }else{
                this.setState({noExisteApellido: true});
            }
        }
        
    }

    handleScan = async(data) => {
        if (data) {
            this.setState({
            result: data
            })
            await this.validaCodigo(data);
        }
    }

    handleError = err => {
        console.error(err)
    }
    
    validaCodigo = async(codigo) => {
        let sesion_codigo = localStorage.getItem('sesion_id');
        let data = JSON.parse(localStorage.getItem(sesion_codigo));
        let currentVenta = null;
        let use_type = sessionStorage.getItem('use_type');
        
        if(data != null && use_type === 'offline'){
            data.forEach(venta => {
                if(codigo === venta.numero_impreso){
                    currentVenta = venta;
                    return;
                }
                if(!currentVenta){
                    venta.detalle.forEach((detalle, i)=>{
                        if(codigo === detalle.venta_detalle_codigo_impreso){
                            let currentDetalle = detalle;
                            currentVenta = venta;
                            currentVenta.detalle = [];
                            currentVenta.detalle.push(currentDetalle);
                        }
                    });
                }
            });
            localStorage.setItem('venta',JSON.stringify(currentVenta));
            if(currentVenta !== null){
                if(currentVenta.requiere_validacion_identidad == 1 
                    && currentVenta.estatus_pago !== 'PAGO_FRAUDE_DEVUELTO') {
                    this.setRedirectValidation();
                } else{
                    this.setRedirect();
                }
            }else{
                this.setState({noExiste: true});
            }
        }else{
            const config = {
                headers: {
                    Authorization: 'Bearer '+sessionStorage.getItem('token')
                }
            }
            const datos = 'sesion_codigo='+sesion_codigo+'&numero_impreso='+codigo;
            await axios.post('check-nro-impreso',datos,config).then(
                res => {
                    
                    if(res.data != null && res.data.venta.length > 0){
                        currentVenta = res.data.venta[0];
                        localStorage.setItem('venta',JSON.stringify(currentVenta));
                        
                        if(currentVenta.type === 'codigo_acceso') {
                            if(currentVenta !== null){
                                this.setRedirect2();
                            }
                        } else {
                            //busco los que estan chequeados para ver si han sido reseteados
                            let dataValidados = localStorage.getItem('validados');
                            let validados = [];
                            if(dataValidados){
                                validados = JSON.parse(dataValidados);
                            }
                            currentVenta.detalle.forEach((det, j) => {
                                let flagExistValidado = false;
                                validados.forEach((validado,i) => {
                                    if(validado.id === det.venta_detalle_codigo_impreso){
                                        flagExistValidado = true;
                                        if(det.chequeo_estatus === 0){
                                            validados.splice(i,1);
                                        }else{
                                            validado.estado = "INGRESADO";
                                        }
                                    }
                                });
                                if(!flagExistValidado && det.chequeo_estatus !== 0){
                                    let datos = {
                                        id: det.venta_detalle_codigo_impreso,
                                        estado: "INGRESADO"
                                    };
                                    validados.push(datos);
                                }
                            });
                            localStorage.setItem('validados',JSON.stringify(validados));

                            if(currentVenta !== null){
                                if(currentVenta.requiere_validacion_identidad == 1 
                                    && currentVenta.estatus_pago !== 'PAGO_FRAUDE_DEVUELTO') {
                                    this.setRedirectValidation();
                                } else{
                                    this.setRedirect();
                                }
                            }
                        }
                    }else{
                        this.setState({noExiste: true});
                        setTimeout(function () { 
                            window.location.reload();
                        }, 2000)
                    }
                },
                err => {
                    console.log(err);
                }
            )
        }
    }

    setRedirect2 = () => {
        this.setState({
          redirect2: true
        })
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/ingreso' />
        }
        if (this.state.redirect2) {
            return <Redirect to='/ingreso2' />
        }
        if (this.state.redirectSesiones) {
            return <Redirect to='/home' />
        }
        
        if (this.state.redirectTotalIngresos) {
            return <Redirect to='/total' />
        }
        
        if (this.state.redirectBusqueda) {
            return <Redirect to='/busqueda' />
        }

        if(this.state.sinSesion){
            sessionStorage.setItem('token',"");
            return <Redirect to="/"/>
        }

        if(this.state.redirectValidacion) {
            return <Redirect to='/validacion-identidad' />
        }
    }

    volver = () => {
        this.setState({
            redirectSesiones: true
          })
    }

    totalIngresos = () => {
        this.setState({
            redirectTotalIngresos: true
          })
    }

    buscarApellido = () => {
        this.setState({
            viewFieldApellido: true
          })
    }

    handleChangeShowModalExplanation = event => {
        if (event.target.checked) {
            localStorage.setItem('showModalExplanationGeneral',false);
        } else {
            localStorage.setItem('showModalExplanationGeneral',true);
        }
    }
    
    setCloseModalHelper = () => {
        localStorage.setItem('showModalExplanation',false);
        this.setState({
            showModalExplanation :false
          })
    }

    setRedirectValidation = () => {
        this.setState({
            redirectValidacion: true
        })
    }

    renderModalExplanation = () => {
        
        if(localStorage.getItem('showModalExplanation') == null || localStorage.getItem('showModalExplanation') == 'true') {

        return <Modal show={this.state.showModalExplanation} className="mt-5">
                <Modal.Header>
                    <Modal.Title><div class="text-center">Consejo para la Correcta Registración de los Ingresos</div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="text-center">
                        <Image src="/help-img.jpeg" 
                    width="auto"
                    height="300"
                    className="img-fluid"
                    alt="EntradaWeb"></Image>
                </div>
                </Modal.Body>
                <Modal.Footer className="text-center">
                {<div className="d-flex justify-content-right">
                                    <label class="tex-right">
                                        <span>No volver a mostrar </span>
                                        <input type="checkbox" onChange={this.handleChangeShowModalExplanation}/>
                                    </label>
                                </div> }
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex justify-content-center">
                                    <Button className="btn-ew btn-block btn-lg btn-naranja" onClick={() => {this.setCloseModalHelper()}}>
                                    Cerrar
                                    </Button>
                                </div> 
                            </Col>
                        </Row>
                    </Container>
                    
                </Modal.Footer>
            </Modal>
        }
    }
      
    render(){
        return (
            <><Nav isLogin={false}></Nav>
            <Container>
                {this.renderRedirect()}
                {this.renderModalExplanation()}
                <Row className="mt-3">
                    <Col md={12}>
                        <h3>{this.state.evento}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {(!this.state.result || this.state.result === '' || this.state.result === undefined) 
                            && <div>
                            <QrReader
                            delay={2}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: '100%' }}
                            />
                        </div>}
                        {this.state.result !== '' && this.state.result !== undefined &&
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        }
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={12}>
                        <Form onSubmit = {this.handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label><b>Número de Venta /Código de Apoyo</b></Form.Label>
                                <FormControl type="text" isInvalid={this.state.noExiste} placeholder="Ingrese Código de Venta" onChange={e => this.codigo = e.target.value }/>
                                <Form.Control.Feedback type="invalid">
                                    Código Inexistente
                                </Form.Control.Feedback>
                            </Form.Group>
                            {this.state.viewFieldApellido && <Form.Group controlId="formBasicApellido">
                                <Form.Label><b> Apellido de Comprador </b></Form.Label>
                                <FormControl type="text" isInvalid={this.state.noExisteApellido} placeholder="Ingrese un Apellido" onChange={e => this.apellido = e.target.value }/>
                                <Form.Control.Feedback type="invalid">
                                    Apellido Inexistente
                                </Form.Control.Feedback>
                            </Form.Group>}
                            <Button type="submit" className="mb-2 btn-ew btn-success" block>
                            <FaCheck></FaCheck> {this.state.viewFieldApellido ? 'Buscar' : 'Validar'}
                            </Button>
                            {!this.state.viewFieldApellido && !this.state.viewButtonApellido &&<Button className="mb-2 btn-ew btn-primary" block onClick={this.buscarApellido}>
                             <FaUserCheck></FaUserCheck> Buscar por Apellido
                            </Button>}
                            {!this.state.viewButtonTotalizado &&
                            <Button className="mb-2 btn-ew btn-info" block onClick={this.totalIngresos}>
                             <FaEquals></FaEquals> Ver Total de Ingresos
                            </Button>
                            }
                            <Button className="mb-2 btn-ew btn-warning" block onClick={this.volver}>
                                <FaArrowLeft></FaArrowLeft> Volver
                            </Button>
                            <br></br>
                        </Form>
                    </Col>
                </Row>
            </Container>     
            </>   
        )
    }
}