import axios from 'axios';
import React, { Component } from 'react'
import { Col, Container, Row, Badge, Button } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa';
import { Redirect } from 'react-router-dom';
import Nav from './Navbar/Nav';


export default class Total extends Component{
    state = {
        redirectSesiones: false,
        hasTotals: false
    }

    constructor(props){
        super(props)
        let sesiones = JSON.parse(localStorage.getItem('sesiones'));
        this.state ={
            recinto: sesiones.recinto,
            sesiones: sesiones.sesiones,
            hasTotals: false,
            infoSectores: []
        };
    }

    componentDidMount() {
        let idSesion = localStorage.getItem('sesion_id');
        let sesiones = JSON.parse(localStorage.getItem('sesiones'));
        let currentSesion = JSON.parse(localStorage.getItem(idSesion));
        let validados = JSON.parse(localStorage.getItem('validados'));
        let use_type = sessionStorage.getItem('use_type');
        let cantidadEntradas = 0;
        let cantidadEntradasChequeadas = 0;
        let porcentaje = 0;

        let selectSesion = null;
        sesiones.sesiones.forEach(sesion => {
            if(sesion.id === idSesion){
                selectSesion = sesion;
            }
        });

        if(navigator.onLine && use_type === 'online'){
            this.getInformation();
        }else{
            if(currentSesion){
                currentSesion.forEach(venta => {
                    cantidadEntradas = cantidadEntradas + venta.detalle.length;
                });
            }
    
            if(validados){
                validados.forEach(validado => {
                    cantidadEntradasChequeadas ++;
                });
            }
    
            if(cantidadEntradas > 0 && cantidadEntradasChequeadas > 0){
                porcentaje = Math.round(cantidadEntradasChequeadas * 100 / cantidadEntradas,2);
            }
        }
        
        this.setState({
            recinto: sesiones.recinto,
            sesiones: sesiones.sesiones,
            fecha_actual: sesiones.fecha_actual,
            sesion: selectSesion,
            cantidadEntradas: cantidadEntradas,
            cantidadEntradasChequeadas: cantidadEntradasChequeadas,
            porcentaje: porcentaje,
            redirectSesiones: false
            });
    }

    async getInformation(){
        let cantidadEntradas = 0;
        let cantidadEntradasChequeadas = 0;
        let porcentaje = 0;

        const config = {
            headers: {
                Authorization: 'Bearer '+sessionStorage.getItem('token')
            }
        }
        const datos = 'sesion_codigo='+localStorage.getItem('sesion_id');
        await axios.post('totalizados',datos,config).then(
            res => {
                
                if(res.data != null){
                    cantidadEntradas = res.data.entradas_vendidas;
                    cantidadEntradasChequeadas = res.data.contador;
                    if(cantidadEntradas > 0 && cantidadEntradasChequeadas > 0){
                        porcentaje = Math.round(cantidadEntradasChequeadas * 100 / cantidadEntradas,2);
                    }
                    this.setState({
                        cantidadEntradas: cantidadEntradas,
                        cantidadEntradasChequeadas: cantidadEntradasChequeadas,
                        porcentaje: porcentaje,
                        redirectSesiones: false,
                        hasTotals: true,
                        infoSectores: res.data.info_sectores
                        });
                }
            },
            err => {
                console.log(err);
            }
        )
    }

    volver = () => {
        this.setState({
            redirectSesiones: true
          })
    }

    renderRedirect = () => {
        if (this.state.redirectSesiones) {
            return <Redirect to='/chequeo' />
        }
    }

    render(){
        return (
            <><Nav isLogin={false}></Nav>
            {this.renderRedirect()}
            <Container hidden={!this.state.hasTotals}>
                <Row className="mt-3">
                    <Col md={12}>
                        <h3>{this.state.recinto.recin_nombre_interno}</h3>
                        <h5>{this.state.fecha_actual}</h5>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <label><b>Cantidad de Entradas Chequeadas</b></label>
                        <h1><Badge variant="success">{this.state.cantidadEntradasChequeadas}</Badge></h1>    
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <label><b>Cantidad de Entradas Generadas</b></label>
                        <h1><Badge variant="info">{this.state.cantidadEntradas}</Badge></h1>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <label><b>Porcentaje de Ingresos</b></label>
                        <h1><Badge variant="success">{this.state.porcentaje}%</Badge></h1>
                    </Col>
                </Row>
                {this.state.infoSectores && this.state.infoSectores.map((info) => 
                    <>
                    <hr></hr>
                    <Row className="mt-4">
                        <Col>
                            <label style={{"fontSize": "25px"}}><b>{info.sector}</b></label>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <label><b>Cantidad de Entradas Chequeadas</b></label>
                            <h1><Badge variant="success">{info.chequeadas}</Badge></h1>    
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <label><b>Cantidad de Entradas Generadas</b></label>
                            <h1><Badge variant="info">{info.vendidas}</Badge></h1>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <label><b>Porcentaje de Ingresos</b></label>
                            <h1><Badge variant="success">{info.porcentaje}%</Badge></h1>
                        </Col>
                    </Row>
                    </>
                )}
                <Row className="mt-5">
                    <Col>
                    <Button className="btn-ew btn-warning" block onClick={this.volver}>
                       <FaArrowLeft></FaArrowLeft> Volver
                            </Button>
                    </Col>
                </Row>
                <br></br>
            </Container>
            </>
            )
    }
}