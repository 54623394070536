import React, { Component } from 'react'
import axios from 'axios';
import { Col, Container, Row, Form, Button, Table, Alert } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { FaCheck, FaArrowLeft, FaRegCheckCircle } from "react-icons/fa";
import Nav from './Navbar/Nav';


export default class ValidacionIdentidad extends Component{
    
    constructor(params){
        super(params)
        this.state = {
            venta: JSON.parse(localStorage.getItem('venta'))
        };
    }

    componentDidMount(){
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/ingreso' />
        }
        if (this.state.redirectChequeo) {
            return <Redirect to='/chequeo' />
        }
    }

    volver = () => {
        this.setState({
            redirectChequeo: true
          })
    }

    volverMarcar = () => {

        const config = {
            headers: {
                Authorization: 'Bearer '+sessionStorage.getItem('token')
            }
        }
        const datos = {valid: true, codigo: this.state.venta.numero_impreso};
        axios.post('validar-fraude',datos,config).then(
            res => {
                
            },
            err => {
                console.log(err);
            }
        )

        this.setState({
            redirectChequeo: true
          })
    }

    totalIngresos = () => {
        this.setState({
            redirectTotalIngresos: true
          })
    }

    getCardNumber = (cardNumber) =>{
        if(cardNumber.length > 0){
            cardNumber = cardNumber.slice(0,(cardNumber.length/2)-1) + "\n" + cardNumber.slice((cardNumber.length/2)-1);
        }
        return cardNumber;
    }

    renderButtonRegister = () => {

        return <Row className="mt-1">
                <Col>
                    <Button type="submit" className="mb-2 btn-ew btn-success" id="btnSincronizar" block onClick={this.registrar}>
                        <FaRegCheckCircle></FaRegCheckCircle> Datos Correctos, ir a Validar
                    </Button>
                </Col>
            </Row>
    }
          
    render(){
        return (
            <><Nav isLogin={false}></Nav>
            <Container>
                {this.renderRedirect()}
                <Form onSubmit = {this.handleSubmit}>
                <Row className="mt-1">
                    <Col md={12}>
                        <Alert variant="danger" className="mt-3">
                            <Alert.Heading>Esta compra requiere Validación de Datos.</Alert.Heading>
                            <p>Solicite ver original o foto de la Tarjeta con la cual se realizó el pago y Verifique Últimos 4 números.</p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table responsive>
                        <thead>
                                <tr>
                                    <th>Medio de Pago</th>
                                    <th>Número de<br></br>Tarjeta</th>
                                    <th>DNI Comprador</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="td-text">{this.state.venta.medio_pago}</td>
                                    <td class="td-text">{this.getCardNumber(this.state.venta.numero_tarjeta)}</td>
                                    <td class="td-text">{this.state.venta.venta_dni_pagador}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {this.renderButtonRegister()}
                <Row>
                    <Col md={12}>
                        <Button className="mb-2 btn-ew btn-danger" block onClick={this.volverMarcar}>
                            <FaArrowLeft></FaArrowLeft> Datos Incorrectos, Volver
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Button className="mb-2 btn-ew btn-warning" block onClick={this.volver}>
                            <FaArrowLeft></FaArrowLeft> Volver
                        </Button>
                        <br></br>
                    </Col>
                </Row>
                </Form>
            </Container>
            </>        
        )
    }
}