import React, { Component } from 'react'
import { Col, Container, Row, Form, Button, Card } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { FaCheck, FaArrowLeft } from "react-icons/fa";
import Nav from './Navbar/Nav';


export default class Busqueda extends Component{
    state = {
        result: '',
        recintoNombre: '',
        codigo : '',
        resultBusqueda: []
    }

    constructor(params){
        super(params)
        let sesiones = JSON.parse(localStorage.getItem('sesiones'));
        let resultBusqueda = JSON.parse(localStorage.getItem('ventas_buscadas'));

        this.state = {recintoNombre : sesiones.recinto.recin_nombre_interno, resultBusqueda: resultBusqueda};
    }

    componentDidMount(){
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.numero_impreso !== undefined){
            this.validaCodigo(this.numero_impreso);
        }
    }

    validaCodigo = codigo => {
        
        let data = JSON.parse(localStorage.getItem('ventas_buscadas'));
        let currentVenta = null;
        
        data.forEach(venta => {
            if(codigo === venta.numero_impreso){
                currentVenta = venta;
                return;
            }
        });
        localStorage.setItem('venta',JSON.stringify(currentVenta));
        if(currentVenta !== null){
            if(currentVenta.requiere_validacion_identidad == 1 
                && currentVenta.estatus_pago !== 'PAGO_FRAUDE_DEVUELTO') {
                this.setRedirectValidation();
            } else{
                this.setRedirect();
            }
        }
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }

    setRedirectValidation = () => {
        this.setState({
            redirectValidacion: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/ingreso' />
        }
        if (this.state.redirectSesiones) {
            return <Redirect to='/chequeo' />
        }
        if(this.state.redirectValidacion) {
            return <Redirect to='/validacion-identidad' />
        }
    }

    volver = () => {
        this.setState({
            redirectSesiones: true
          })
    }

    totalIngresos = () => {
        this.setState({
            redirectTotalIngresos: true
          })
    }
          
    render(){
        return (
            <><Nav isLogin={false}></Nav>
            <Container>
                {this.renderRedirect()}
                <Row className="mt-3">
                    <Col md={12}>
                        <h3>{this.state.recintoNombre}</h3>
                    </Col>
                </Row>
                <Form onSubmit = {this.handleSubmit}>
                {this.state.resultBusqueda.map((venta) =>
                <Row key={venta.numero_impreso}>
                    <Col md={12}>
                        <Card border="primary" className="mx-auto mt-3">
                            <Card.Body>
                                <Form.Check.Label><b>{venta.comprador} <br></br> {venta.venta_email}</b></Form.Check.Label>
                                <Form.Check
                                    type="radio"
                                    name="radio">
                                    <Form.Check.Input value={venta.numero_impreso} type="radio" name="radio" onClick={(e) => {this.setState({ habilitaSincronizacion: true }); this.numero_impreso = e.target.value;}}/>
                                </Form.Check>
                                <br></br>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>)}
                <Row className="mt-3">
                    <Col md={12}>
                            <Button type="submit" className="mb-2 btn-ew btn-success" block>
                                <FaCheck></FaCheck> Validar
                            </Button>
                            <Button className="mb-2 btn-ew btn-warning" block onClick={this.volver}>
                                <FaArrowLeft></FaArrowLeft> Volver
                            </Button>
                            <br></br>
                    </Col>
                </Row>
                </Form>
            </Container>
            </>        
        )
    }
}