import axios from 'axios';
import React, { Component } from 'react'
import { Col, Container, Row, Button, Table, Badge, Form } from 'react-bootstrap'
import { FaRegCheckCircle, FaArrowLeft } from "react-icons/fa";
import { Redirect } from 'react-router-dom';
import Nav from './Navbar/Nav';
import CheckboxButton from './CheckboxButton/CheckboxButton';

export default class Ingreso2 extends Component{
    
    constructor(params){
        super(params)
        this.state = {
            venta: JSON.parse(localStorage.getItem('venta')),
            validadas : [],
            aValidar : [],
            checked : false,
        };
    }

    
    handleChange = (id,value) => {
        let aValidar = this.state.aValidar;
        if(id){
            var index = aValidar.indexOf(id)
            if (index !== -1) {
                aValidar.splice(index, 1);
            }else{
                aValidar.push(id);
            }
            this.setState({ aValidar :aValidar });
        }
    }

    getClassButton = (id) =>{
        let validados = JSON.parse(localStorage.getItem('validados'));
        let isValidado = "NO_INGRESADO";
        
        if(navigator.onLine){
            let venta = this.state.venta;
            if(venta.numero_impreso === id){
                if(venta.chequeo_estatus === 1){
                    isValidado = 'INGRESADO'
                }else if(venta.chequeo_estatus === 2){
                    isValidado = 'SALIO'
                }else{
                    isValidado = 'NO_INGRESADO'
                }
            }
            
        }
        
        let classColor = 'success';

        if(isValidado === 'INGRESADO'){
            classColor = 'danger';
        }else if(isValidado === 'SALIO'){
            classColor = 'warning';
        }
        
        return classColor;
    }

    checkeaEstado = (id) => {
        let validados = JSON.parse(localStorage.getItem('validados'));
        let isValidado = "NO_INGRESADO";
        
        if(navigator.onLine){
            let venta = this.state.venta;
            if(venta.numero_impreso === id){
                if(venta.chequeo_estatus === 1){
                    isValidado = 'INGRESADO'
                }else if(venta.chequeo_estatus === 2){
                    isValidado = 'SALIO'
                }else{
                    isValidado = 'NO_INGRESADO'
                }
            }
            
        }else{
            if(validados){
                validados.forEach((validado)=>{
                    if(validado.id === id){
                        isValidado = validado.estado;
                        return;
                    }
                });
            }
        }
        
        return isValidado;
    }

    volver = () =>{
        this.setRedirect();
    }

    registrar = () =>{
        let aValidar = this.state.aValidar;
        let dataValidados = localStorage.getItem('validados');
        let use_type = sessionStorage.getItem('use_type');
        let validados = [];
        let validadosSend = [];
        
        if(dataValidados){
            validados = JSON.parse(dataValidados);
        }
        aValidar.forEach(id => {
            let estado = 'INGRESADO'

            validados.forEach((validado,i) => {
                if(validado.id === id && validado.estado === 'INGRESADO'){
                    estado = 'SALIO'
                    validados.splice(i,1);
                }
            });

            let datos = {
                id: id,
                estado: estado
            };
            validadosSend.push(datos);
            validados.push(datos);
        });

        if(navigator.onLine && use_type === 'online'){
            const config = {
                headers: {
                    Authorization: 'Bearer '+sessionStorage.getItem('token')
                }
            }
            const datos = {chequeo_estatus: validadosSend};
            axios.post('registrar-ingresos',datos,config).then(
                res => {
                    console.log(res);
                    if(res){
                        
                    }
                    localStorage.setItem('validados',JSON.stringify(validados));
                },
                err => {
                    console.log(err);
                }
            )
        }else{
            localStorage.setItem('validados',JSON.stringify(validados));
        }
        
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
      }
      
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/chequeo' />
        }
    }

    render(){
        

        return (
            <><Nav isLogin={false}></Nav>
            <Container>
            <Form onSubmit = {this.handleSubmit}>
                {this.renderRedirect()}
                <Row className="mt-3">
                    <Col>
                        <Button type="submit" className="mb-2 btn-ew btn-success" id="btnSincronizar" block onClick={this.registrar}>
                            <FaRegCheckCircle></FaRegCheckCircle> Registrar y Volver
                        </Button>
                    </Col>
                </Row>
                <Row key={this.state.venta.numero_impreso}>
                    <Col>
                                <Table responsive className="table-ingreso text-center mb-0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="mt-3 mb-0 text-left">
                                                    <CheckboxButton parentCallback={this.handleChange} id={this.state.venta.numero_impreso} variante={(this.getClassButton(this.state.venta.numero_impreso))}></CheckboxButton>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="mt-3 mb-0 text-center">
                                                    {this.state.venta.sesion}
                                                </p>
                                            </td>
                                            <td>
                                                <div className="mt-3 mb-0 text-center">
                                                    <Badge variant={this.getClassButton(this.state.venta.numero_impreso)}>{this.checkeaEstado(this.state.venta.numero_impreso)}</Badge>
                                                </div>
                                            </td>
                                        </tr>
                                     </tbody>
                                </Table>
                                <br></br>
                                {this.state.venta.eev_nombre_interno}
                    </Col>
                </Row> 
                <Row>
                    <Col>
                        <Button type="submit" className="mb-2 btn-ew btn-success" id="btnSincronizar" block onClick={this.registrar}>
                        <FaRegCheckCircle></FaRegCheckCircle> Registrar y Volver
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="submit" className="mb-2 btn-ew btn-warning" id="btnSincronizar" block onClick={this.volver}>
                        <FaArrowLeft></FaArrowLeft> Volver
                        </Button>
                    </Col>
                </Row>
                </Form>
            </Container>
            </>
        )
    }
}