import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';


export default class Logout extends Component{
    render(){
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('sesiones');
        sessionStorage.removeItem('sesion_id');
        return (<Redirect to="/"/>);
    }

}