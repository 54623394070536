import React from 'react';
import {Button} from 'react-bootstrap';

class CheckboxButton extends React.Component {

    constructor(props) {
      super(props);
      this.state = {isToggleOn: false};
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
      this.setState(
        prevState => ({
        isToggleOn: !prevState.isToggleOn
        })
      );
      this.sendData();
    }

    sendData = () => {
      this.props.parentCallback(this.props.id,(!this.state.isToggleOn));
    }

  render() {
    return (
      
      <>
        <Button variant={this.state.isToggleOn ? 'primary' : (this.props.variante)} onClick={this.handleClick}> {this.props.id}
          <input type="hidden" onChange={e => this.sendData(e.target.id,e.target.value)} id={this.props.id} value={this.state.isToggleOn ? true : false}></input>
        </Button>
      </>
    );
  }
}

export default CheckboxButton;