import axios from 'axios'
import React, { Component } from 'react'
import { Col, Container, Row, Card, Form, Button, Modal, Alert } from 'react-bootstrap'
import { FaSyncAlt,FaQrcode } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import Nav from './Navbar/Nav';

export default class Home extends Component{
    
    state = {recinto:[],sesiones: [], fecha_actual: null, cantidadSesiones:0,exitoSincronizado:false}

    constructor(params){
        super(params)
        localStorage.setItem('sesion_id','');
        if(!navigator.onLine && sessionStorage.getItem('use_type') === 'offline') {
            let data = JSON.parse(localStorage.getItem('sesiones'));
            
            if(data){
                this.state = {
                    recinto: data.recinto,
                    sesiones: data.sesiones,
                    fecha_actual: data.fecha_actual,
                    cantidadSesiones: data.sesiones.length,
                    habilitaSincronizacion: (data.sesiones.length ===1),
                    viewSesionTodas: true
                };   
            }
        }
    }
    
    componentDidMount(){
        
        let use_type = sessionStorage.getItem('use_type');
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                Authorization: 'Bearer '+token
            }
        }
        
        if((!token || token === 'undefined') && use_type === 'online'){
            this.setState({redirectLogin: true});
        }else{
            if(use_type === 'online'){

                axios.get('sesion',config).then(
                    res => {
                        this.setState({
                            recinto: res.data.Recinto,
                            sesiones: res.data.sesiones,
                            fecha_actual: res.data.fechaActual,
                            cantidadSesiones: res.data.sesiones.length,
                            habilitaSincronizacion: (res.data.sesiones.length >=1),
                            viewSesionTodas: (res.data.Recinto.tipo === 'CODE')
                        });   
                        
                        let data ={
                            recinto: res.data.Recinto,
                            sesiones: res.data.sesiones,
                            fecha_actual: res.data.fechaActual,
                            sesion_fecha: res.data.eev_sesion_fecha
                        };
    
                        if(res.data.sesiones.length >= 1){
                            res.data.sesiones.forEach((sesion) => {
                                localStorage.setItem('sesion_id',sesion.id);
                            });
                        }
    
                        
                        localStorage.setItem('sesiones',JSON.stringify(data));
                    },
                    err => {
                        let data = JSON.parse(localStorage.getItem('sesiones'));
                        if(data){
                            this.setState({
                                recinto: data.recinto,
                                sesiones: data.sesiones,
                                fecha_actual: data.fecha_actual,
                                cantidadSesiones: data.sesiones.length,
                                habilitaSincronizacion: (data.sesiones.length ===1)
                            });   
    
                            if(data.sesiones.length >= 1){
                                data.sesiones.forEach((sesion) => {
                                    localStorage.setItem('sesion_id',sesion.id);
                                });
                            }
                        }
                    }
                )
                
                //chequea si hay algo para subir
                let dataValidados = localStorage.getItem('validados');

                if(dataValidados != null && dataValidados.length > 0){
                    const chequeos = {
                        chequeos: "martin"
                    }
                    axios.post('sincronizar',chequeos,config).then(
                        res => {
                            
                        }
                    ).catch(
                        err => {
                            console.log(err);
                        }
                    );
                }
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({aSincronizar : false});
        
        if(this.state.aSincronizar){
            let savedSesionId = localStorage.getItem('sesion_id');
            
            if(!this.sesion_id){
                this.sesion_id = savedSesionId;
            }
    
            const data = 'sesion_codigo='+this.sesion_id;
    
            if(navigator.onLine) {
                const config = {
                    headers: {
                        Authorization: 'Bearer '+sessionStorage.getItem('token')
                    }
                }
                axios.post('sesion-data',data,config).then(
                    res => {
                        localStorage.setItem(this.sesion_id,JSON.stringify(res.data));
                        localStorage.setItem('sesion_id',this.sesion_id);
                        if(res.data.length > 0){
                            this.setState({exitoSincronizado:true});
                        }
                    },
                    err => {
                        console.log(err);
                    }
                )
            }
        }else{
            this.setState({aSincronizar : true});
        }
    }

    setRedirect = () => {
        let sesion = localStorage.getItem('sesion_id');
        if(sesion === undefined || sesion === null || sesion === ""){
            localStorage.setItem('sesion_id','todas'); 
        }
        if(!localStorage.getItem('showModalExplanationGeneral') || localStorage.getItem('showModalExplanationGeneral') == true){
            localStorage.setItem('showModalExplanation',true);
        }
        
        this.setState({
          redirect: true
        })
    }

    setSesionChecked = (id) => {
        localStorage.setItem('sesion_id',id); 
    }

    sincornizadoOk = () => {
        this.setState({
            redirect: true
        })
    }
      
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/chequeo' />
        }
        if (this.state.redirectLogin) {
            return <Redirect to='/' />
        }
    }

    printSincronizar= ()=>{
        if(navigator.onLine && this.state.habilitaSincronizacion) {
            return (<Button type="submit" className="mb-2 btn-ew" disabled={!this.state.habilitaSincronizacion} id="btnSincronizar">
            <FaSyncAlt></FaSyncAlt> Sincronizar
            </Button>)
        }
    }

    printChequeo= ()=>{
        if(this.state.viewSesionTodas || this.state.cantidadSesiones >= 1){
            return (<Button type="submit" className="mb-2 btn-ew btn-success" id="btnSincronizar" block onClick={this.setRedirect}>
                        <FaQrcode></FaQrcode> Comenzar Chequeo
                    </Button>)
        }else{
            if(this.state.recinto.tipo === 'USER'){
                return (<Alert variant="light">
                    <Alert.Heading>No tienes eventos para Control de Acceso para el día de hoy</Alert.Heading>
                </Alert>)

            }
        }
    }

    render(){
        
        return (
            <><Nav isLogin={false}></Nav>
            <Container>
                {this.renderRedirect()}
                <Modal show={this.state.exitoSincronizado} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>Sincronización</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Sincronización realizada con Éxito</Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-ew btn-naranja" onClick={() => {this.setState({exitoSincronizado :false})}}>
                        Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Form onSubmit = {this.handleSubmit}>
                <Modal show={this.state.aSincronizar} className="mt-5">
                    <Modal.Header closeButton>
                        <Modal.Title>Sincronización</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Recuerde, que al menos por ahora, la sincronización para trabajar offline solo se debe utilizar si hay solo un chequeador en el evento</Modal.Body>
                    <Modal.Footer className="text-center">
                        <Button type="submit" className="btn-ew btn-success" onClick={this.handleSubmit}>
                        Sincronizar
                        </Button>
                        <Button className="btn-ew btn-naranja" onClick={() => {this.setState({aSincronizar :false})}}>
                        No Sincronizar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Row className="mt-3">
                    <Col md={12}>
                        <p className="title-home">{this.state.recinto.recin_nombre_interno}</p>
                        <p className="fecha-home">{this.state.fecha_actual}</p>
                    </Col>
                </Row>
                { 
                this.state.sesiones.map((sesion) => 
                <Row key={sesion.id}>
                    <Col md={12}>
                        <Card border="primary" className="mx-auto mt-3">
                            <Card.Body>
                                    <Form.Check
                                        type="radio"
                                        name="radio">
                                        <Form.Check.Input value={sesion.id} type="radio" name="radio" onClick={(e) => {this.setState({ habilitaSincronizacion: true }); this.setSesionChecked(e.target.value);}} defaultChecked={this.state.cantidadSesiones>=1}/>
                                        <Form.Check.Label><b>{sesion.evento} <br></br> {sesion.sesion_hora_inicio}hs</b></Form.Check.Label>
                                    </Form.Check>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>)}
                {this.state.viewSesionTodas && this.state.recinto.tipo === 'CODE' &&
                <Row>
                    <Col md={12}>
                        <Card border="primary" className="mx-auto mt-3">
                            <Card.Body>
                                    <Form.Check
                                        type="radio"
                                        name="radio">
                                        <Form.Check.Input value="todos_eventos" type="radio" name="radio" onClick={(e) => {this.setSesionChecked(e.target.value);this.setState({todasSesiones:true});this.setState({ habilitaSincronizacion: false });}}/>
                                        <Form.Check.Label><b>Cualquier Evento de Hoy de este Recinto</b></Form.Check.Label>
                                    </Form.Check>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
                {this.state.viewSesionTodas && this.state.recinto.tipo === 'CODE' &&
                <Row>
                    <Col md={12}>
                        <Card border="primary" className="mx-auto mt-3">
                            <Card.Body>
                                    <Form.Check
                                        type="radio"
                                        name="radio">
                                        <Form.Check.Input value="todas" type="radio" name="radio" onClick={(e) => {this.setSesionChecked(e.target.value);this.setState({todasSesiones:true});this.setState({ habilitaSincronizacion: false });}} defaultChecked={this.state.cantidadSesiones===0}/>
                                        <Form.Check.Label><b>Cualquier Evento</b></Form.Check.Label>
                                    </Form.Check>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
                <Row className="mt-3">
                    <Col md={12}>
                        {this.printChequeo()}
                    </Col>
                </Row>
                
                <br></br>
                <br></br>
                <Row>
                    <Col className="fixed-bottom justify-content-start ">
                    </Col>
                    <Col className="fixed-bottom justify-content-end">
                            {this.printSincronizar()}
                    </Col>
                    <Col className="fixed-bottom justify-content-end ">
                    </Col>
                </Row>
                
                </Form>
            </Container>     
            </>   
        )
    }
}