import axios from 'axios';
import React, { Component } from 'react'
import {Form, InputGroup, FormControl,Container, Row, Col, Image, Button} from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { AiOutlineLogin, AiOutlineArrowLeft } from "react-icons/ai";

export default class Login extends Component{
    state = {
        redirect: false,
        redirectInicio: false,
        redirectLogout: false,
        viewOffline: false
    }

    constructor(params){
        super(params)
        let token = sessionStorage.getItem('token');

        if(token && token !== "" && token !== "undefined" && navigator.onLine){
            this.state = { redirect: true };
        }
    }

    componentDidMount(){
        
    }

    handleBack = () => {
        this.setState({
            redirectInicio: true
          })
    }

    renderBack = () => {
        if (this.state.redirectInicio) {
            return <Redirect to='/' />
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        const data = 'username='+this.username+'&password='+this.password;
        
        if(navigator.onLine) {
            axios.post('login-user-pass',data).then(
                res => {
                    
                    if(res.data.status !== '' && res.data.status === 'error'){
                        this.setState({ redirectLogout: true });
                    }
    
                    if(res.data.token !== ""){
                        sessionStorage.setItem('token',res.data.token);
                        sessionStorage.setItem('use_type','online');

                        this.setState({ redirect: true });
                    }
                }
            ).catch(
                err => {
                    console.log(err);
                    this.setState({ redirect: false });
                    this.refreshPage();
                }
            );
        }else{
            let sesiones = localStorage.getItem('sesiones');
            if(sesiones && sesiones.length > 0){
                sessionStorage.setItem('use_type','offline');
                this.setState({ redirect: true });
            }
        }
        
    }
    
    render(){
        const { redirect } = this.state;
        const { redirectLogout } = this.state;
        const { redirectInicio } = this.state;

        if (redirectLogout) {
            return (<Redirect to="/logout"/>);
        }
        if (redirect) {
            return (<Redirect to="/home"/>);
        }
        if (redirectInicio) {
            return (<Redirect to="/inicio"/>);
        }
            return (
                <><div className="login-wrap">
                    {this.renderBack()}
                <Container>
                    <Row>
                        <Col className="mt-5">
                            <Image src="/logotipo300.png" fluid width="85%"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            <Form onSubmit = {this.handleSubmit}>
                                <Form.Row className="align-items-center text-center">
                                    <Col>
                                        <InputGroup className="mb-2">
                                            <FormControl type="text" id="login-codigo" placeholder="Ingrese el Usuario" onChange= {e => this.username = e.target.value } />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="align-items-center text-center">
                                    <Col>
                                        <InputGroup className="mb-2">
                                            <FormControl type="password" id="login-codigo" placeholder="Ingrese la Contraseña" onChange= {e => this.password = e.target.value } />
                                        </InputGroup>
                                    </Col>
                                </Form.Row>
                                <br></br>
                                <br></br>
                                <Form.Row>
                                <Col>
                                    <button type="submit" className="btn btn-ew btn-naranja btn-block mb-4">
                                        <b><AiOutlineLogin></AiOutlineLogin> INGRESA</b>
                                    </button>
                                    <Button size="lg" className="btn btn-ew btn-naranja btn-block" onClick={this.handleBack}>
                                        <b><AiOutlineArrowLeft></AiOutlineArrowLeft> VOLVER</b>
                                    </Button>
                                </Col>
                                </Form.Row>
                            </Form>
                            
                        </Col>
                    </Row>
                    <Row className="title-login">
                        <Col>
                            <h2>Control de Acceso</h2>
                        </Col>
                    </Row>
                </Container>
                </div>
                </>
            )
    
    }
}